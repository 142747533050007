@import url(https://fonts.googleapis.com/css2?family=Muli:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer{
      margin:0;
      box-sizing:border-box;
      font-family: 'Muli', sans-serif;
  }
  img[src="https://cdn.000webhost.com/000webhost/logo/footer-powered-by-000webhost-white2.png"]{
    display:none;
}
  /*.loader {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > img {
  width: 100px;
}

.loader.hidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  100% {
      opacity: 0;
      visibility: hidden;
  }
}

.thumb {
  height: 100px;
  border: 1px solid black;
  margin: 10px;
}*/
  html {
scroll-behavior:smooth;
--scrollbarBG: white;
   --thumbBG:#d9dedb; 
        }
        body::-webkit-scrollbar {
        width: 11px;
        
}
body {
scrollbar-width: thin;
scrollbar-color: #d9dedb white;
scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
background: white;
background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
background-color: #d9dedb;
background-color: var(--thumbBG) ;
border-radius: 4px;
border: 1px solid white;
border: 1px solid var(--scrollbarBG);
}
.icon-bar {
position: fixed;
top: 50%;
transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
z-index: 10;
}

.icon-bar a {
text-decoration: none;
width: 120px;
background: #3b5999;
color: #fff;
display: flex;
justify-content: flex-end;
align-items: center;
padding: 5px;
font-size: 20px;
transform: translateX(-78px);
-webkit-transform: translateX(-78px);
-ms-transform: translateX(-78px);
transition: all 0.5s linear;
-webkit-transition: all 0.5s linear;
-ms-transition: all 0.5s linear;
}

.facebook {
background: #3b5998!important;
}

.twitter {
background:#28A745!important;
}

.instagram {
background: #e4405f!important;
}

.youtube {
background: #cd201f!important;
}

.linkedin {
background: #0077B5!important;
}


.icon-bar a i {
padding-left: 12px;
font-size: 30px !important;
animation: letszoom 3s linear alternate-reverse infinite;
}

@-webkit-keyframes letszoom {
from {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

to {
  -webkit-transform: scale(1);
          transform: scale(1);
}

}

@keyframes letszoom {
from {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

to {
  -webkit-transform: scale(1);
          transform: scale(1);
}

}

.icon-bar a:hover {
transform: translateX(0);
-webkit-transform: translateX(0);
-ms-transform: translateX(0);
}

.ck{
   -webkit-animation-name: ck;
           animation-name: ck;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  /*animation:ck 2s linear alternate reverse infinite;*/
}
@-webkit-keyframes ck {
0%   {transform: scale(0.7);-webkit-transform: scale(0.7);-ms-transform: scale(0.7);color:#1F3B5C;font-weight:bold;}
25%  {transform: scale(0.8);-webkit-transform: scale(0.8);-ms-transform: scale(0.8);color:red;font-weight:bold;}
50%  {transform: scale(0.9);-webkit-transform: scale(0.9);-ms-transform: scale(0.9);color:#8c0b45;font-weight:bold;}
75%  {transform: scale(1.0);-webkit-transform: scale(1.0);-ms-transform: scale(1.0);color:green;font-weight:bold;}
100% {transform: scale(1.02);-webkit-transform: scale(1.02);-ms-transform: scale(1.02);color:#1390c2;font-weight:bold;}
}
@keyframes ck {
0%   {transform: scale(0.7);-webkit-transform: scale(0.7);-ms-transform: scale(0.7);color:#1F3B5C;font-weight:bold;}
25%  {transform: scale(0.8);-webkit-transform: scale(0.8);-ms-transform: scale(0.8);color:red;font-weight:bold;}
50%  {transform: scale(0.9);-webkit-transform: scale(0.9);-ms-transform: scale(0.9);color:#8c0b45;font-weight:bold;}
75%  {transform: scale(1.0);-webkit-transform: scale(1.0);-ms-transform: scale(1.0);color:green;font-weight:bold;}
100% {transform: scale(1.02);-webkit-transform: scale(1.02);-ms-transform: scale(1.02);color:#1390c2;font-weight:bold;}
}
.imo{
   color:#266494;
   }
.card-title{
    text-shadow: 2px 2px 4px #000;    
}    

  .shadow{
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transition:all 0.3s linear;
      -webkit-transition:all 0.3s linear;
      -ms-transition:all 0.3s linear;
      background-image:url('https://amitsolution.co.in/wave.png');
      font-weight:bold!important;
  }
    
   .pack:hover{
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.4)!important;
     font-weight: bold;
     transition:all 0.3s linear;
     -webkit-transition:all 0.3s linear;
      -ms-transition:all 0.3s linear;
     transform: scale(1.0);
     -webkit-transform: scale(1.0);
      -ms-transform: scale(1.0);
      border-radius:10%;
      /*filter: grayscale(100%)!important;
      width: 308px;
      height: 300px;
      opacity: 1;
transform: rotateZ(360deg)!important;*/
border-style:solid!important;
  -webkit-animation-name: ak!important;
          animation-name: ak!important;
  -webkit-animation-duration: 2s!important;
          animation-duration: 2s!important;
  -webkit-animation-iteration-count: infinite!important;
          animation-iteration-count: infinite!important;
      
       
  }
  
  @-webkit-keyframes ak {
0%   {border-color:#1F3B5C;}
25%  {border-color:red;}
50%  {border-color:#8c0b45;}
75%  {border-color:green;}
100% {border-color:#1390c2;}
}
  
  @keyframes ak {
0%   {border-color:#1F3B5C;}
25%  {border-color:red;}
50%  {border-color:#8c0b45;}
75%  {border-color:green;}
100% {border-color:#1390c2;}
}
  .ic:hover{
      visibility: visible!important;
  }
  web{
     margin-top:10px; 
  }
  .act
  {
      color:orange;
  }
  .amit{
      background-color:black!important;
      color:white!important;
      -webkit-animation-name: amit;
              animation-name: amit;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
      
  }
  @-webkit-keyframes amit {
0%   {background-color:#1F3B5C;}
25%  {background-color:red;}
50%  {background-color:#8c0b45;}
75%  {background-color:green;}
100% {background-color:#1390c2;}
}
  @keyframes amit {
0%   {background-color:#1F3B5C;}
25%  {background-color:red;}
50%  {background-color:#8c0b45;}
75%  {background-color:green;}
100% {background-color:#1390c2;}
}
  .mobile{
 background: #4b6cb7;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
text-shadow: 2px 2px 4px #000!important;    


  }
  .btn-info{
      outline: none!important;
    border: none!important;
     box-shadow: none!important;  
background-image: linear-gradient(to right, #ee0979, #ff6a00)!important;
  }
  .btn-design{
      outline: none!important;
    border: none!important;
     box-shadow: none!important;
      background-image: linear-gradient(to right, #0575e6, #021b79)!important;
  }
  .btn-design:active{
background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f)!important;
}
.btn-design2{
      outline: none!important;
    border: none!important;
     box-shadow: none!important;
      background-image: linear-gradient(to right, #c0392b, #8e44ad)!important;
  }
  .btn-design2:active{
background-position: right!important;
}
  input[type="text"]:focus,input[type="number"]:focus,input[type="email"]:focus,textarea:focus {
           font-weight: bold;
           color:#1F3B5C;
           border-radius:7px;
      }
  .blink{
      color:white!important;
      margin-left:5px;
       margin-right:5px;
       text-transform:uppercase;
       text-decoration:none!important;
       position:relative;
  }
  .blink:before{
      content:'';
      position:absolute;
      top:100%;
      bottom:50%;
      width:0%;
      height:3%;
      background:orange;
      transition:all 0.3s linear;
  }
  .blink:hover{
      transition:all 0.3s linear;
       text-shadow: 2px 2px 4px #000;
       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.4)!important;
  }
  .card0{
     text-align:center; 
  }
  .parallax {
/* The image used */
background-image: url("https://amitsolution.co.in/sl1.jpg");

/* Set a specific height */
min-height: 200px; 
margin-bottom:10px;
/* Create the parallax scrolling effect */
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
 
      @media screen and (max-width: 1200px) {
          img[src="https://cdn.000webhost.com/000webhost/logo/footer-powered-by-000webhost-white2.png"]{
    display:none;
}
.sld{
    margin:0px!important;
    padding:0px!important;
}
#hide {
  display:none!important;
}

.carousel-inner
{
    height:150px!important;
    
}

.shadow0
{
    margin-top:10px!important;
}
iframe{
    width:100%!important;
    overflow:hidden!important;
}
.icon-bar{
    display:none!important;
}
#l{
    width:100%!important;
}

}
